<template>
    <div class="padding-20">
        <div class="flex-row align-items-center margin-bottom-20 font-size-18"><i class="el-icon-arrow-left font-size-20 margin-right-5 link"  @click="$router.go(-1)"></i>调整权限</div>
        <div class="column userinfocolumn">
            <div class="flex-row">
                <div class="border-right padding-right-40  margin-right-40">
                   <div class="font-size-12 color-sub margin-bottom-5 line-height-mini">账号</div>
                   <div class="font-size-14 bold"> {{userinfo.loginName}}</div>
                </div>
                <div class="border-right padding-right-40 margin-right-40  line-height-mini">
                   <div class="font-size-12 color-sub margin-bottom-5  ">姓名</div>
                   <div class="font-size-14 bold"> {{userinfo.userName}}</div>
                </div>
                <div class="border-right padding-right-40  margin-right-40  line-height-mini">
                   <div class="font-size-12 color-sub margin-bottom-5 ">部门</div>
                   <div class="font-size-14 bold"> {{userinfo.orgName}}</div>
                </div>
                <div class="">
                   <div class="font-size-12 color-sub margin-bottom-5  line-height-mini">岗位</div>
                   <div class="font-size-14 bold"> {{userinfo.jobName}}</div>
                </div>
            </div>
        </div>
        <div class="column">
            <powercomponens :userlist="[userid]" ></powercomponens>
        </div>
    </div>
    </template>
    <script>
    import powercomponens from './powercomponens.vue';
    export default {
    components: { powercomponens },
        name:"editpowerforuser",
        data() {
           return{
             userid:'',
             userinfo:{}
           } 
        },
        methods:{
            getuserinfo(){
                this.userList=[];
                this.$instance.get('/manage/getAccountInfo',{params:{userId:this.userid}}).then(res=>{
                    if(res.data.code==0){
                        this.userinfo=res.data.info;
                    }
                })
            }
        },
        created(){
            if(this.$route.query.uid){
                this.userid=this.$route.query.uid
            }
        },
        mounted(){
            
           this.getuserinfo()
        }
    }
    </script>
    <style lang="scss">
    .userinfocolumn{
        padding:13px 20px;
    }
    </style>
    