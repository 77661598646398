<template>
    <div class="flex-row">
        <div class="rolleft border-right padding-right-30">
            <div class="flex-row justify-between align-items-center margin-bottom-10">
                <div>选择角色模板</div>
                <el-button size="mini" @click="addRole">添加角色</el-button>
            </div>
            <el-checkbox-group v-model="checkList" @change="changeselect" :disabled="!isshow">
                <ul class="border-top padding-top-5">
                    <li class="flex-row justify-between align-items-center" v-for="(item,index) of rolList" :key="index">
                      <el-checkbox :label="item.roleCode">{{item.roleName}}</el-checkbox> 
                      <div>
                        <el-button type="text" class="color-sub" @click="deleteRole(item.roleCode)"><i class="el-icon-delete"></i></el-button>
                        <el-button type="text" class="color-sub" @click="editRole(item)"><i class="el-icon-edit"></i></el-button>
                      </div>
                    </li>
                </ul>
               
              
            </el-checkbox-group>
        </div>
        <div class="rolright padding-left-30" v-loading="pageloading">
            <div class=" padding-bottom-15 padding-top-5 ">为账号设置权限</div>
            <div class="scrollcontent border-top padding-top-15">
                <div v-for="(item,index) of powerList" :key="index" class="border-bottom margin-bottom-20">
                    <div class="margin-bottom-10 ">{{item.moduleName}}</div>
                    <div class="flex-row  padding-bottom-25" v-if="item.hasProduct==1">
                        <div class="margin-right-30">
                            <el-checkbox v-model="item.isall" @change="handleCheckAllChange(index,$event)" >全部</el-checkbox>
                        </div>
                        <el-checkbox-group v-model="item.productlist" @change="setselectall(index,$event)">
                            <el-checkbox v-for="(prod,index) in allproduct.filter(o=>o.productId>0)" :label="prod.productId" :key="index">{{prod.productName}}</el-checkbox>
                        </el-checkbox-group>
                    </div>
                 </div>
            </div>
            
            <div  class="flex-row justify-center">
                <!-- <el-button  style="width:180px" size="mini" v-show="powerList.length>0" @click="$router.push('/powermanage/userlist')"></el-button> -->
                <el-button type="primary" style="width:180px" size="mini" v-show="powerList.length>0" @click="savepower">保存</el-button>
            </div>
        </div>
        <!-- 添加修改角色弹窗 -->
        <el-dialog
            :title="actRole.id>0?'角色修改':'角色添加'"
            :visible.sync="showroleEdit"
            custom-class="custom-dialog rolewindow"
            width="30%"
            top="10vh"
            :append-to-body=true
          >
            <div class="" v-loading="editloading">
                <el-form ref="roleform" :model="actRole" label-width="80px" :rules="rules" >
                    <el-form-item label="角色名称"  prop="roleName">
                        <el-input v-model="actRole.roleName" size="small"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="角色编号"  prop="roleCode">
                        <el-input v-model="actRole.roleCode" size="small"></el-input>
                    </el-form-item> -->
                    <el-form-item label="角色权限" class="rolemodeltree">
                        <el-tree
                            :data="modelList"
                            :props="defaultProps"
                            show-checkbox
                            node-key="id"
                            ref="tree"
                            default-expand-all
                         >
                        </el-tree>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showroleEdit = false">取 消</el-button>
                <el-button type="primary" @click="saveRoleset">确 定</el-button>
            </span>
        </el-dialog>

        <!-- 添加修改校色弹窗开始 -->
    </div>
</template>
<script>

export default {
    data() {
      return{
        checkList:[],
        rolList:[],
        showroleEdit:false,
        actRole:{},
        modelList:[],
        powerList:[],
        rules: {
          roleName: [
            { required: true, message: '请输入角色名称', trigger: 'blur' },
          ],
          roleCode: [
            { required: true, message: '请输入角色编号', trigger: 'blur' }
          ]
          
        },
        defaultProps: {
          children: 'children',
          label: 'moduleName'
        },
        allproduct:[],
        checkmodelList:[],
        pageloading:false,
        editloading:false,//编辑角色树菜单加载
        isshow:false
      }  
    },
    props:{
        userlist:{
            default:[]
        },
        islist:{//批量还是单个设置
            default:false
        }
    },
    methods:{
        addRole(){
            this.actRole={
                roleName:'',
                moduleInfoPojos:[]
            }
            this.showroleEdit=true;
            this.$nextTick(()=>{
                this.$refs.tree.setCheckedKeys([]);
            })
            
        },
        changeselect(list){
            var that=this;
            this.pageloading=true;
            this.$nextTick(async ()=>{
                that.powerList=[];
                  //获取每个角色的模块添加到powerList里面
                    for(let o of list){
                       await this.$instance.get('/manage/getRoleInfo',{params:{roleCode:o}}).then(res=>{//获取角色对应品目
                            if(res.data.code==0){
                                let searchinfo=function(pname,vallist){
                                    for(let item of vallist){
                                        if(item.children&&item.children.length>0){
                                            searchinfo(pname.length>0?pname+' - '+item.moduleName:item.moduleName,item.children);
                                        }else{
                                           if(that.powerList.findIndex(j=>{return j.moduleCode==item.moduleCode})<0){
                                                that.powerList.push({
                                                    ...item,
                                                    productlist:[],
                                                    isall:false,
                                                    moduleName:pname.length>0?pname+" - "+item.moduleName:item.moduleName
                                                    
                                                });
                                            }
                                        }
                                        
                                    }
                                }
                                if(res.data.info.moduleInfoPojos){
                                    searchinfo("",res.data.info.moduleInfoPojos);
                                }
                            }
                        })
                    }
                    //获取每个角色的产品权限
                    if(!that.islist){//判断是否是单个用户设置，只有单个用户设置才涉及回显权限，批量不涉及
                        for(let mod of this.powerList){
                                  that.$instance.get('/manage/getProductPowerManage',{params:{userId:that.userlist[0],moduleCode:mod.moduleCode}}).then(res=>{
                                        if(res.data.code==0 && res.data.info){
                                            if(res.data.info.findIndex(j=>j.productId==0)>=0){
                                                //全部产品
                                                mod.isall=true;
                                                mod.productlist=this.allproduct.filter(j=>j.productId>0).map(k=>{return k.productId})
                                            }else{
                                                mod.productlist=res.data.info.map(j=>{return j.productId})
                                            }
                                                                
                                        }
                                  })
                        } 
                    }
                    this.pageloading=false;
      
            })
        },
        editRole(role){
            this.editloading=true;
            this.actRole=role;
            const defaultcheck=[];
            this.$instance.get('/manage/getRoleInfo',{params:{roleCode:role.roleCode}}).then(res=>{
                            if(res.data.code==0){
                                this.showroleEdit=true;
                                let searchinfo=function(vallist){
                                    for(let item of vallist){
                                        
                                       
                                        if(item.children&&item.children.length>0){
                                            searchinfo(item.children);
                                        }else{
                                            defaultcheck.push(item.id);
                                        }
                                    }
                                }
                                if(res.data.info.moduleInfoPojos){
                                    searchinfo(res.data.info.moduleInfoPojos);
                                }
                                this.$nextTick(()=>{
                                    this.$refs.tree.setCheckedKeys(defaultcheck);
                                })
                               
                                this.editloading=false;
                            }
            })
        },
      
        getrolList(){
            //获取所有角色列表
            this.rolList=[];
            //console.log(this.userlist);
            this.$instance.get('/manage/getRoleList',!this.islist?{params:{userId:this.userlist[0]}}:{}).then(res=>{
                if(res.data.code==0){
                    this.rolList=res.data.info;
                    if(!this.islist){
                        //单个用户默认选择
                        this.checkList=this.rolList.filter(o=>o.isClick).map(j=>{
                            return j.roleCode;
                        })
                        //
                        this.changeselect(this.checkList)//加载右侧栏目
                    }
                }
            })
        },
        getModule(){
            //获取所有角色列表
            this.rolList=[];
            this.$instance.get('/manage/getModuleList').then(res=>{
                if(res.data.code==0){
                    this.modelList=res.data.info;
                    
                }
            })
        },
        deleteRole(rcode){
            //删除角色
            this.$confirm('确定要删除角色吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
            }).then(() => {
                this.$instance.post('/manage/delRole?roleCode='+rcode,{}).then(res=>{
                if(res.data.code==0){
                    this.rolList=res.data.info;
                    this.$message({
                            message: '操作成功',
                            type: 'success'
                    });
                    this.getrolList();
                }
                // else{
                //     this.$message({
                //             message: res.data.msg,
                //             type: 'error'
                //     });
                // }
            })
            })
            
        },
        saveRoleset(){
            //保存角色配置
            this.$refs['roleform'].validate((valid) => {
                if (valid) {
                     //通过验证
                    if(this.actRole.id && this.actRole.id>0){
                        let rolelist=this.$refs.tree.getHalfCheckedNodes().map(o=>{return  {moduleCode:o.moduleCode}})
                    const temprole={
                        id: this.actRole.id,
                        roleCode: this.actRole.roleCode,
                        roleName: this.actRole.roleName,
                        moduleInfoPojos:rolelist.concat(this.$refs.tree.getCheckedNodes().map(o=>{return  {moduleCode:o.moduleCode}})) 
                    }
                    //console.log(temprole);
                    //更新
                    this.$instance.post('/manage/updateRole',temprole).then(res=>{
                        //console.log(res);
                        if(res.data.code==0){
                            this.$message({
                                message: '操作成功',
                                type: 'success'
                            });
                            this.getrolList();
                            this.showroleEdit=false;
                        }
                    })
                }else{
                     //更新
                    const temprole={
                        roleName: this.actRole.roleName,
                        roleCode: this.actRole.roleCode,
                        moduleInfoPojos: this.$refs.tree.getCheckedNodes().map(o=>{return {moduleCode:o.moduleCode}})
                    }
                    //console.log(temprole);
                    this.$instance.post('/manage/insertRole',temprole).then(res=>{
                        if(res.data.code==0){
                            this.$message({
                                message: '操作成功',
                                type: 'success'
                            });
                            this.getrolList();
                            this.showroleEdit=false;
                        }
                    })
                }
          } else {
           // console.log('error submit!!');
            return false;
          }
        });
        },
        getproductList(){
            this.$instance.get('/manage/getAllProduct').then(res=>{
                if(res.data.code==0){
                    this.allproduct=res.data.info;
                }
            })
        },
        handleCheckAllChange(index,val){
            //全选
            if(val){
                this.powerList[index].productlist=this.allproduct.filter(o=>o.productId>0).map(j=>{
                     return j.productId;
                });
            }else{
                this.powerList[index].productlist=[];
            }
            
        },
        setselectall(index,val){//产品点击事件
            if(val.length==this.allproduct.filter(o=>o.productId>0).length){
                this.powerList[index].isall=true;
            }else{
                this.powerList[index].isall=false;
            }
            
        },
        savepower(){
          
            if(this.userlist.length<=0 && this.islist){
                this.$message.error('请先选中用户');
                return false;
            }
            
            //保存模块权限
            let valued=true;//标记
            const tempdata=[];
            for(let o of this.powerList){
                let prod=[]
                if(o.isall){
                    //选择全部就直传0
                    if(this.allproduct.filter(o=>o.productName=='全部').length>0){
                        prod=[{
                            productId:this.allproduct.filter(o=>o.productName=='全部')[0].productId,
                            powerType:this.allproduct.filter(o=>o.productName=='全部')[0].powerType
                        }]
                    }else{
                        prod=[{
                            productId:0,
                            powerType:0
                        }]
                    }
                }else{
                    //循环选中的产品
                    for(let i of o.productlist){
                        prod.push({
                            productId:i,
                            powerType:this.allproduct.filter(o=>o.productId==i)[0].powerType||0
                        })
                    }
                }
                if(prod.length==0 && o.hasProduct=="1"){
                    this.$message.error(o.moduleName+'未设置产品，请先勾选产品');
                    valued=false;
                    break;
                }
                if(o.hasProduct=="1"){
                    //必传产品的才会更新，其他的模块权限随角色编辑更新
                    tempdata.push({
                        userId:this.userlist.join(','),
                        moduleCode:o.moduleCode,
                        products:prod
                    })
                }
               
            }
           
            if(!valued){
                return false
            }
           // console.log(tempdata);
           
            //保存角色信息
            this.$instance.post('/manage/updateRoleUser',{userId:this.userlist.join(','),roleCode:this.checkList.join(',')}).then(res=>{
                       if(res.data.code==0){
                        //保存产品信息
                            this.$instance.post('/manage/updateProductPower',tempdata).then(res=>{
                                        if(res.data.code==0){
                                            this.$message({
                                                message: '操作成功',
                                                type: 'success'
                                            });
                                    }
                            })
                       }
            })
        },
        getcheck(){
            this.$instance.get('/manage/checkUseIam').then(res=>{
                if(res.data.code==0){
                    this.isshow=!res.data.info;
                }
            })
          }
    },
    computed:{
       
    },
    mounted(){
        this.getcheck();
        this.getrolList();
        this.getModule();
        this.getproductList();
    }
}
</script>
<style lang="scss" >
.rolleft{
    box-sizing: border-box;
    width: 300px;
    min-height: 500px;
}
.scrollcontent{
    max-height: calc(100vh - 245px);
    overflow-y: auto;
}
.rolright{
    width: calc(100% - 320px);
}
.rolewindow{
    .el-dialog__body{
        padding: 20;
        padding-bottom: 0;
        padding-top: 10px;
        .el-form-item{
            margin-bottom: 16px;
        }
    }
    .rolemodeltree{
        margin-bottom: 0!important;
        .el-form-item__content{
            max-height: calc(80vh - 230px);
            overflow-y: auto;
            
        }
        .el-form-item__error{
                padding-top: 0!important;
        }
    }
   
 
}
</style>
